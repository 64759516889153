import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { fetchPractices } from "../../../actions";

import queryString from "query-string";
import { Location } from "@reach/router";

import AuditForm from "./auditForm";

const Index = () => {
  const dispatch = useDispatch();
  const [loading, update_loading] = useState(true);

  useEffect(() => {
    dispatch(fetchPractices());
    update_loading(false);
  }, []);

  function returnForm(location, navigate) {
    return (
      <AuditForm
        location={location}
        navigate={navigate}
        search={location.search ? queryString.parse(location.search) : {}}
      ></AuditForm>
    );
  }

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <Location>
        {({ location, navigate }) => returnForm(location, navigate)}
      </Location>
    </div>
  );
};

export default Index;
